import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="temp09_info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>放射〈非接触〉FAQ</h2>
					<div className="content-container">
						<div className="office_inner">
							<ul className={"temp09_info-flex"}>
								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										動いている物体や回転している物体の温度は測定できますか。
									</p>
								</li>
								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
										放射温度計は物体から放射される赤外線の量を測定して温度測定をおこないますので、物体に触れずに測定できます。したがって、動いていたり、回転しているもののほか、フィルムや紙など熱容量の小さいもの、極めて高温のもの、高電圧のもの、遠距離にあるものなど接触型温度計では測定できないものでも、簡単に温度測定ができます。
									</p>
								</li>

								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										ガラスを通して物体の温度が測れますか。
									</p>
								</li>

								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
										ガラスは赤外線に対して、約2.5μmより短波長を透過、長波長を吸収する性質をもっています。したがって、放射温度計でも約2.5μmより短波長を使う放射温度計（一般に中高温用）は、ガラスを通して測定できます。しかし、約2.5μmより長波長を使う放射温度計（一般に低温用）では測定できません。
									</p>
								</li>

								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										放射率のわからない物体の測定方法はどうしたらいいですか。
									</p>
								</li>

								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
										測定したい物体の上にペイントやテープのような放射率の高いものを貼りつけてその温度を測定します。放射率を知りたい場合は、貼っていないところを測定し、貼ってあるところを測定した値と同じになるように放射率を設定し、その値を読みます。また、ペイントやテープの代わりに接触型の温度計を使って測定し、その温度になるように放射率を合わせる方法もあります。
									</p>
								</li>

								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										気体や炎の温度は、測定できますか。
									</p>
								</li>

								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
										気体や炎は、背後にあるほかの物質から放射する赤外線の一部を透過させるため、専用の放射温度計が必要です。
									</p>
								</li>

								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										測定距離の変化によって、温度は影響されませんか。
									</p>
								</li>

								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
										一般に、測定物体の距離が遠くなれば、受ける赤外線の量は少なくなり、近くなれば多くなります。また、近距離の場合、測定面積は小さくなり、受光する赤外線の量は少なくなります。遠距離の場合は、測定面積は広く、受光する赤外線の量も多くなります。このように、単位面積当たりの赤外線量を測定する放射温度計は、近距離でも遠距離でも赤外線量が同じになり距離の影響はありません。
									</p>
								</li>

								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										雨や霧を通して測ることができますか。
									</p>
								</li>

								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
									    程度にもよりますが、赤外線は水滴によって吸収や散乱されます。したがって、正確度は期待できません。
									</p>
								</li>

								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										夜など暗いところでも測れますか。
									</p>
								</li>

								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
										放射温度計は物体からでている赤外線を測定しますので、暗いところでも測れます。
									</p>
								</li>

								<li className="flex temp09_question_background">
									<p className={"temp09_question info-title"}>Q</p>
									<p className={"temp09_question_bold medium info-body"}>
										太陽光や蛍光灯の影響を受けますか。
									</p>
								</li>

								<li className="flex">
									<p className={"medium temp09_answer  info-title"}>A</p>
									<p className={"medium info-body"}>
										蛍光灯の光は、長波長側は0.8μmぐらいまでしか出ていません。温度も40～50℃までしか上がらないと言われています。そのため通常の測定には、ほとんど影響がありません。しかし、太陽光や白熱電球は長波長の赤外線を出しており、影響を受ける場合があります。太陽や電球が反射してキラキラ光っているところを測定する場合は、特に注意が必要です。
									</p>
								</li>
							</ul>
						</div>
					</div>
					<PagingController current={9} next={'/support/documents/about-temperature/temp10'} prev={'/support/documents/about-temperature/temp08'} />
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
